export default {
  "menu_index": "インデックス",
  "menu_about": "会社概要",
  "menu_team": "プロフェッショナルチーム",
  "menu_business": "事業範囲",
  "menu_case": "ケース提出",
  "menu_info": "法的情報",
  "menu_success": "成功事例",
  "menu_language": "言語切り替え",
  "Integrity": "誠実さ",
  "major": "専門性",
  "responsibility": "責任",
  "efficiency": "効率",
  "index_intro1": "当社は、",
  "index_intro2": "に基づいて、お客様に幅広い法律サービスを提供しています",
  "index_intro2_orange": "専門性",
  "index_intro3_orange": "効率",
  "index_intro3_white": "と",
  "index_intro3_orange2": "革新。",
  "elite_professional": "エリートプロフェッショナル法律事務所",
  "get_in_touch": "お問い合わせ",
  "about_sub": "会社概要",
  "law_name": "Watson Liddell Law Firm",
  "about_intro1": " 当社の弁護士チームは、詐欺、資産追跡および回収事件の取り扱いに経験豊富であり、詐欺、虚偽または不正行為によって失われた資産や金銭を回収するのを支援します。 私たちは資産を追跡する効果的な戦略を開発します。",
  "about_intro2": " 私たちは、株式投資詐欺、暗号通貨詐欺、関係詐欺、パートタイムジョブ詐欺など、サイバー関連の犯罪の取り扱いに特化しています。",
  "about_intro3": " 特に、資金詐欺、金銭の横領、社会詐欺、パートタイム犯罪に関連するコンピュータ犯罪に取り組んでいます。",
  "year": "年",
  "established": "設立",
  "number_of_customers_served": "サービスを受けた顧客数",
  "service_case": "サービスケース",
  "people": "人",
  "senior_consultant": "シニアコンサルタント",
  "professional_body": "専門機関",
  "team_sub": "チーム",
  "anti_fraud_information": "詐欺防止情報",
  "anti_sub": "ニュースと情報",
  "more": "詳細",
  "swiper_text1": "誠実さ、専門性、責任、効率",
  "swiper_text2": "Watson Liddell Law Firmは、",
  "swiper_text3": "高品質でプロフェッショナルな法律事務所を構築することを約束します",
  "professional_team": "プロフェッショナルチーム",
  "business_tip": "Watson Liddell Law Firmの誠実さと信頼性、勤勉さと誠実さは、当社の基盤です。 プラグマティズムと法律を遵守し、目標を達成することは、Watson Liddell Law Firmの不断の追求です。",
  "scope1": "投資詐欺",
  "scope2": "経済紛争",
  "scope3": "企業法務",
  "scope4": "個人弁護士",
  "scope5": "金融訴訟",
  "scope6": "反トラスト法",
  "scope7": "債権回収",
  "scope8": "その他のビジネス",
  "case_consultation": "ケース相談",
  "case_inquiry": "ケース問い合わせ",
  "consultation_sub": "あなたの意見は私たちが前進する原動力です",
  "case_type": "ケースタイプ",
  "select_scam_type": "詐欺タイプを選択してください",
  "your_case": "あなたのケース",
  "please_enter_feedback": "あなたのケースのフィードバックを入力してください",
  "your_phone": "あなたの携帯電話番号",
  "please_enter_your_phone": "電話番号を入力してください",
  "submit_click": "ワンクリックで送信",
  "please_enter_phone": "電話番号を入力してください",
  "search": "検索",
  "no_relevant": "関連する返信が見つかりません",
  "success_title": "クラシック成功事例",
  "bot_tele": "電話",
  "bot_address": "住所",
  "bot_cooperation": "協力機関の承認",
  "quick_links": "クイックリンク",
  "CFTC": "商品先物取引委員会（CFTC）を含む国際金融規制機関",
  "FINRA": "FINRA（FINRA）",
  "FINMA": "スイス金融市場監督機構（FINMA）",
  "FCA": "英国金融行動監視機構（FCA）",
  "HKMA": "香港金融管理局（HKMA）",
  "CFPB": "消費者金融保護局（CFPB）",
  "case_option1": "インターネット募金詐欺",
  "case_option2": "インターネット株式投資詐欺",
  "case_option3": "仮想通貨詐欺",
  "case_option4": "恋愛詐欺",
  "case_option5": "eコマース詐欺",
  "case_option6": "パートタイム詐欺",
  "case_option7": "その他の詐欺",
  "case_detail": "ケースの詳細",
  "reply_message": "返信メッセージ",
  "submission_time": "提出時間",
  "home_service": "カスタマーサービス"
}
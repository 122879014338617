export default {
  "menu_index": "인덱스",
  "menu_about": "회사 소개",
  "menu_team": "전문가 팀",
  "menu_business": "사업 범위",
  "menu_case": "사건 제출",
  "menu_info": "법률 정보",
  "menu_success": "성공 사례",
  "menu_language": "언어 전환",
  "Integrity": "성실",
  "major": "주요",
  "responsibility": "책임",
  "efficiency": "효율성",
  "index_intro1": "우리는 고객에게 제공하고 있습니다.",
  "index_intro2": "전문성을 기반으로 한 다양한 법률 서비스",
  "index_intro2_orange": "전문성",
  "index_intro3_orange": "효율성",
  "index_intro3_white": " 및",
  "index_intro3_orange2": "혁신.",
  "elite_professional": "엘리트 프로페셔널 로펌",
  "get_in_touch": "문의하기",
  "about_sub": "회사 소개",
  "law_name": "Watson Liddell Law Firm",
  "about_intro1": "우리의 변호사 팀은 사기, 자산 추적 및 회수 사건을 다루는 데 경험이 있으며, 사기, 거짓말 또는 부정직으로 인한 자산 및 돈을 회수하는 데 도움을 줍니다. 자산을 추적하기 위한 효과적인 전략을 개발합니다.",
  "about_intro2": "우리는 주식 펀드 투자 사기, 암호화폐 사기, 관계 사기, 아르바이트 사기 등을 포함한 사이버 범죄에 전문화되어 있습니다.",
  "about_intro3": "특히, 자금 사기, 돈 횡령, 사회 사기 및 아르바이트 범죄와 관련된 컴퓨터 범죄를 다룹니다.",
  "year": "년",
  "established": "설립",
  "number_of_customers_served": "고객 수",
  "service_case": "서비스 사례",
  "people": "사람들",
  "senior_consultant": "수석 컨설턴트",
  "professional_body": "전문 기관",
  "team_sub": "팀",
  "anti_fraud_information": "사기 방지 정보",
  "anti_sub": "뉴스 및 정보",
  "more": "더 보기",
  "swiper_text1": "성실, 전문성, 책임, 효율성",
  "swiper_text2": "Watson Liddell Law Firm은",
  "swiper_text3": "고품질 및 전문 로펌을 구축하기로 했습니다.",
  "professional_team": "전문가 팀",
  "business_tip": "정직과 신뢰, 부지런함과 성실함은 Watson Liddell Law Firm의 기초입니다. 실용적이고 법을 준수하며 목표를 달성하는 것은 Watson Liddell Law Firm의 끊임없는 추구입니다.",
  "scope1": "투자 사기",
  "scope2": "경제적 분쟁",
  "scope3": "기업 법률 사안",
  "scope4": "개인 변호사",
  "scope5": "금융 소송",
  "scope6": "반독점법",
  "scope7": "채권 회수",
  "scope8": "기타 사업",
  "case_consultation": "사례 상담",
  "case_inquiry": "사례 조회",
  "consultation_sub": "귀하의 의견은 우리가 전진하는 원동력입니다.",
  "case_type": "사건 유형",
  "select_scam_type": "사기 유형 선택",
  "your_case": "귀하의 사례",
  "please_enter_feedback": "귀하의 사례 피드백을 입력하세요.",
  "your_phone": "귀하의 휴대폰 번호",
  "please_enter_your_phone": "전화 번호를 입력하세요.",
  "submit_click": "한 번의 클릭으로 제출",
  "please_enter_phone": "전화 번호를 입력하세요.",
  "search": "검색",
  "no_relevant": "관련된 답변 없음",
  "success_title": "클래식한 성공 사례",
  "bot_tele": "전화",
  "bot_address": "주소",
  "bot_cooperation": "협력 인증 기관",
  "quick_links": "빠른 링크",
  "CFTC": "CFTC를 포함한 국제 금융 규제 기관",
  "FINRA": "FINRA",
  "FINMA": "스위스 금융 시장 감독 기관 (FINMA)",
  "FCA": "영국 금융 거래 위원회 (FCA)",
  "HKMA": "홍콩 통화 기구 (HKMA)",
  "CFPB": "소비자 금융 보호국 (CFPB)",
  "case_option1": "인터넷 모금 사기",
  "case_option2": "인터넷 주식 펀드 투자 사기",
  "case_option3": "암호화폐 사기",
  "case_option4": "연애 사기",
  "case_option5": "전자 상거래 사기",
  "case_option6": "아르바이트 사기",
  "case_option7": "기타 사기",
  "case_detail": "사건 세부 정보",
  "reply_message": "답장 메시지",
  "submission_time": "제출 시간",
  "home_service": "고객 서비스"
}